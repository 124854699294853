import React from 'react'
import { Link } from "react-router-dom";

const Button = ({
  theme,
  children,
  loading,
  onClick,
  to,
  disabled,
}) => {
  let extraClasses = "";
  switch (theme) {
    case "grayLead":
      extraClasses = "bg-gray text-white";
      break;
    case "warning":
      extraClasses = "bg-orange text-primary";
      break;
    default:
      break;
  }

  if (disabled) {
    extraClasses += " opacity-50 cursor-not-allowed pointer-events-none";
  }

  const className = `inline-block px-4 py-1 text-xs text-center rounded cursor-pointer relative ${extraClasses}`;

  const loadingImage = (
    <>
      <img
        className="absolute w-4 h-4 -mt-2 -ml-2 left-1/2 top-1/2"
        src="/assets/images/spinner.gif"
        alt="..."
      />
    </>
  );

  const content = (
    <>
      {loading && loadingImage}
      <div className={`${loading ? "invisible" : ""}`}>{children}</div>
    </>
  );

  if (to) {
    return (
      <Link className={className} to={to}>
        {content}
      </Link>
    );
  }

  return (
    <span className={className} onClick={onClick}>
      {content}
    </span>
  );
};

export default Button;
