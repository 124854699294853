import React, { useState, useEffect } from 'react'
import config from '../config'
import Cookies from 'js-cookie'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import Container from './Container'

const Deploy = () => {
  const [selectedCaches, setSelectedCaches] = useState(['webinars', 'blog'])
  const [info, setInfo] = useState(false)
  const [fetching, setFetching] = useState(false)

  let user = { id: -1 }
  if (Cookies.get('user')) {
    user = JSON.parse(Cookies.get('user'))
  }

  useEffect(() => {
    fetch(makeUrl('deploy-status', []), {
      method: 'GET',
      headers: {
      },
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          setInfo(response.body)

          setTimeout(() => {
            if (!fetching) {
              window.location.reload()
            }
          }, 60 * 1000)

        } else {
          console.log('Error', response.status, response.body.error)
        }
      })
  }, [])

  function makeUrl(endpoint, params = []) {

    const strArr = [];
    strArr.push('now=' + Date.now());
    Object.keys(params).forEach(key => {
      strArr.push(key + '=' + params[key]);
    })
    return `${config.apiURL}api/${endpoint}?${strArr.join('&')}`
  }

  const triggerPost = (endpoint) => {

    if (fetching) return

    const msg = endpoint === 'cache' ? 'This will cache the contents without a deploy' : 'A cache and deploy will start. It might take a while to complete.'

    if (window.confirm(msg)) {
      setFetching(true)
      fetch(`https://c.hpvirtualbooth.com/node/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: typeof user.id !== 'undefined' ? user.id : -1,
          token: '8a0cb3fb9386b85a413f7f69e58b624a88bbc352',
          caches: selectedCaches.join(','),
          env: config.environment,
        }),
      })
        .then(response => {
          if (endpoint === 'deploy-production') {
            window.open('https://largeformat.hp.com/')
          } else if (endpoint === 'deploy-stage') {
            window.open('https://hp-virtual-web.now.sh/')
          }
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        })
    }
  }

  const status = {
    'started': 'A caching process started on',
    'failed': 'A caching attempt failed on',
    'netlify-started': 'A publishing process started on',
    'netlify-failed': 'A publishing attempt failed on',
  }

  const caches = [
    'home',
    'verticals',
    'printerslist',
    'printersdetail',
    'webinars',
    'blog',
    '3dstand',
    'forms',
    'podcasts',
    'stories',
    'solutions',
    'videos',
    'howto',
    'printers360',
    'covid',
  ]

  const handleChange = c => {
    if (selectedCaches.includes(c)) {
      setSelectedCaches([...selectedCaches].filter(cache => cache !== c))
    } else {
      const newSelectedCaches = [...selectedCaches]
      newSelectedCaches.push(c)
      setSelectedCaches(newSelectedCaches)
    }
  }

  return (
    <Container className="pt-10">
      <Helmet>
        <title>Deploy</title>
      </Helmet>
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl wght-semibold">Deploy</h2>
      </div>
      {info && <div>

        {info.lastDeploy && <div data-userid={info.lastDeploy.user_id} className="p-4 mb-4 rounded bg-green">Last publication: {moment.utc(info.lastDeploy.date).utcOffset(moment().utcOffset()).format('DD/MM/YYYY HH:mm:ss')}</div>}
        {user.role === 'ROLE_ADMIN' && info.lastCache && <div data-userid={info.lastCache.user_id} className="p-4 mb-4 bg-white rounded">Last cached content: {moment.utc(info.lastCache.date).utcOffset(moment().utcOffset()).format('DD/MM/YYYY HH:mm:ss')}</div>}

        {Object.keys(status).indexOf(info.current.status) > -1 && <div data-userid={info.current.user_id} className="p-4 mb-4 rounded bg-orange">{status[info.current.status]} {moment.utc(info.current.date).utcOffset(moment().utcOffset()).format('DD/MM/YYYY HH:mm:ss')}.</div>}

        {fetching && <div>One moment...</div>}

        {(!info.current || ['netlify-ended', 'netlify-failed', 'failed', 'ended'].indexOf(info.current.status) > -1 || moment(info.current.date).diff(moment(), 'minutes') < -15) && !fetching && <div>

          <div className="mb-4">
            <h2>☢️ EXPERT MODE: only use if you know what you're doing</h2>
            <div className="flex flex-wrap">
              {caches.map(c => <span key={c} onClick={() => handleChange(c)} className="mr-4 text-xs cursor-pointer"><input checked={selectedCaches.includes(c)} className="mr-2" onChange={() => handleChange(c)} type="checkbox" value={c} />{c}</span>)}
            </div>
          </div>

          <div className="flex">
            <span className="p-2 mr-4 text-xs rounded cursor-pointer bg-purple" onClick={() => triggerPost('deploy-production')}>Cache &amp; Deploy {config.environment?.toUpperCase()}</span>
            {/* TODO: Prepare a preview branch, independent from staging and production */}
            {/* <span className="p-2 mr-4 text-xs rounded cursor-pointer bg-purple" onClick={() => triggerPost('deploy-stage')}>Cache &amp; Deploy PREVIEW</span> */}
            {user.role === 'ROLE_ADMIN' &&
              <span className="p-2 mr-4 text-xs bg-white rounded cursor-pointer" onClick={() => triggerPost('cache')}>Cache contents</span>}
          </div>

        </div>}

      </div>}
    </Container>
  )
}

export default Deploy