import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useHistory,
} from 'react-router-dom'
import config from '../config'
import Edit from './Edit'
import List from './List'
import Deploy from './Deploy'
import Images from './Images'
import Container from './Container'
import Cookies from 'js-cookie'
import EnvironmentTag from './EnvironmentTag'
import Button from "./Button"

const Main = () => {

  const history = useHistory()
  const logout = () => {
    Cookies.remove('token')
    Cookies.remove('user')
    history.replace('/login')
  }
  let username = 'Anonymous'
  let user = null
  if (Cookies.get('user')) {
    user = JSON.parse(Cookies.get('user'))
    if (user) {
      username = user.name
    }
  }

  let menu = config.menu
  if (user.role === 'ROLE_WEBINAR') {
    menu = config.menuWebinar
  } else if (user.role === 'ROLE_STORIES') {
    menu = config.menuStories
  } else if (user.role === 'ROLE_BLOG') {
    menu = config.menuBlog
  }

  const isMenuItemActive = (item) => {
    let active = false
    if (typeof item.items !== 'undefined') {
      item.items.forEach(subitem => {
        if (!active && history.location.pathname.includes(subitem.type)) {
          active = true
        }
      })
    }
    if (!active) {
      return history.location.pathname.includes(item.type)
    }
    return active
  }

  return (
    <div className="flex flex-col min-h-screen">
      <nav className="bg-white">
        <Container className="justify-between my-6 md:flex">
          <div className="flex">
            <div>
              <EnvironmentTag config={config} />
            </div>
            <table>
              <ul className="flex flex-wrap items-center">
                {menu.map(item => (
                  <li key={item.type} className="mr-4">
                    <NavLink className="hover:wght-semibold" activeClassName="wght-semibold" to={item.path || `/admin/list/${item.type}`}>
                      {item.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
              {menu.map(item => {
                if (isMenuItemActive(item)) {
                  return (
                    <ul key={item.type} className="flex mt-1 text-xs">
                      {
                        item.items && item.items.map(subitem => (
                          <li key={subitem.type} className="mr-4">
                            <NavLink className="hover:wght-semibold" activeClassName="wght-semibold" to={subitem.path || `/admin/list/${subitem.type}`}>
                              {subitem.title}
                            </NavLink>
                          </li>
                        ))
                      }
                    </ul>
                  )
                }

                return null
              })}
            </table>
          </div>
          <div className="hidden wght-semibold md:block ">
            <div className="flex gap-2">
              <Button
                theme="warning"
                to={`/admin/images`}
              >
                Images
              </Button>
              <Button
                theme="warning"
                to={`/admin/deploy`}
              >
                Deploy
              </Button>
            </div>
          </div>
        </Container>
      </nav>
        <Switch>
          <Route path="/admin/list/:name/:id?">
            <List />
          </Route>
          <Route path="/admin/edit/:name/:id?">
            <Edit />
          </Route>
          <Route path="/admin/deploy">
            <Deploy />
          </Route>
          <Route path="/admin/images">
            <Images />
          </Route>
        </Switch>
      <div className="py-12 bg-white">
        <Container>
          <div className="text-xs">
            <EnvironmentTag config={config} />
            👋 Hi <span className="capitalize">{username}</span>, ¿would you like to <span onClick={logout} className="cursor-pointer wght-bold hover:wght-semibold">log out</span>?
          </div>
        </Container>
      </div>
    </div >
  )
}
export default Main
