import React, { useState, useEffect } from 'react'
import {
  useParams,
  useHistory,
  Link,
} from 'react-router-dom'
import config from '../config'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import Container from './Container'
import Button from "./Button"
import generateToken from "./Lib/generateToken"

const numberOfItems = 2000

const defaultFilter = {
  id: generateToken(18),
  column: 'ref',
  operator: '=',
  search: ''
}

const List = () => {
  const history = useHistory()
  const { name: modelName, id } = useParams()
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(modelName)
  const [duplicatedId, setDuplicatedId] = useState(false)
  const [page, setPage] = useState(0)
  const [hasMorePages, setHasMorePages] = useState(false)
  const [showTranslations, setShowTranslations] = useState(false)
  const [downloadingTranslate, setDownloadingTranslate] = useState(false)
  const [sort, setSort] = useState({})
  const [showFilters, setshowFilters] = useState(false)
  const [filters, setFilters] = useState([])

  const getData = async ({ sort = {}, filters = {} }) => {
    try {
      const response = await fetch(
        `${config.apiURL}models/list/${modelName}/${numberOfItems}/${encodeURIComponent(
          JSON.stringify(sort)
        )}/${encodeURIComponent(
          JSON.stringify(filters)
        )}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${Cookies.get('token')} `,
          },
        })

      const { status = 400, body } = await response.json().then(data => ({ status: response.status, body: data })) || {}

      if (status === 200) {
        if (modelName === 'Post') {
          delete body?.config?.list?.excludedFrom
        }
        setData(body)
        setHasMorePages(body.length === numberOfItems)
      } else {
        console.log('🚀 ~ handleSort ~ err:', status, body.error)
        if (status === 403) {
          Cookies.remove('token')
          history.push('/login')
        }
      }
    } catch (err) {
      console.log('🚀 ~ handleSort ~ err:', err)
    }
  }

  const handleSort = async (key) => {
    setSort(state => ({ [key]: state[key] === 'asc' || data?.config?.sort[key] === 'asc' ? 'desc' : 'asc' }))
  }

  useEffect(() => {
    if (Object.keys(sort).length || filters.length) getData({ sort, filters })
  }, [sort, filters])

  useEffect(() => {
    setData(false)
    setshowFilters(false)
    setSort({})
    // TODO: refactor
    let title = modelName
    config.menu.forEach(item => {
      if (item.type === modelName && title === modelName) {
        title = item.title
      } else if (item.items) {
        item.items.forEach(item2 => {
          if (item2.type === modelName && title === modelName) {
            title = item2.title
          }
        })
      }
    })
    setTitle(`List ${title} `)
    getData({})
  }, [modelName, duplicatedId])

  const remove = (id) => {
    if (window.confirm('Delete?')) {
      fetch(`${config.apiURL}models/delete/${modelName}/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 200) {
            data.items = data.items.filter(item => item.id !== id)
            setData({ ...data })
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('token')
              history.push('/login')
            }
          }
        })
    }
  }

  const duplicate = (id) => {
    if (window.confirm('Duplicate?')) {
      fetch(`${config.apiURL}models/duplicate/${modelName}/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 200) {
            setDuplicatedId(response.body.id)
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('token')
              history.push('/login')
            }
          }
        })
    }
  }

  const Exports = () => {
    const download = () => {
      if (data && window.confirm(`This will open [${data.items.length}] tabs. Are you sure you want to procede? (You must UNBLOCK this page from opening tabs)`)) {
        data.items.forEach((item, i) => {
          window.open(`${config.apiURL}models/translate/export/${modelName}/${item.id}/download`)
        })
      }
    }

    return (
      <div className="my-4">
        <div className="text-sm cursor-pointer" onClick={() => setShowTranslations(!showTranslations)}>{showTranslations ? 'Hide translations' : 'Show translations'}</div>
        <div className={`${showTranslations ? 'block' : 'hidden'}`}>
          <div className="p-4 mb-4 text-xs bg-white rounded">
            {downloadingTranslate ?
              <span className="cursor-pointer hover:wght-semibold">Exporting translation...</span>
              : <span onClick={download} className="text-xs cursor-pointer hover:wght-semibold">Export translations</span>}
          </div>
        </div>
      </div>
    )
  }

  const handleFilters = (filterId, event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => {
      return prevFilters.map((filter) => {
        if (filter.id !== filterId) return filter;

        if (name === "column") {
          return {
            ...filter,
            column: value,
            operator: filterOptions(value)[0].value
          };
        }

        return { ...filter, [name]: value };
      });
    });
  };

  const handleAddFilter = () => {
    setFilters(oldFilters => [
      ...oldFilters,
      { ...defaultFilter, logicalOperator: 'and', id: generateToken(18) }
    ]);
  };

  const filterOptions = (type = 'default', id) => {
    switch (type) {
      case 'id':
        return [
          { id: generateToken(18), value: '=', label: '=' },
          { id: generateToken(18), value: '!=', label: '≠' },
          { id: generateToken(18), value: '>', label: '>' },
          { id: generateToken(18), value: '<', label: '<' },
          { id: generateToken(18), value: '<=', label: '≤' },
          { id: generateToken(18), value: '>=', label: '≥' }
        ]
      case 'updateDate':
        return [
          { id: generateToken(18), value: 'between', label: 'is between' },
          { id: generateToken(18), value: '>=', label: 'is on or after' },
          { id: generateToken(18), value: '<=', label: 'is on or before' },
          { id: generateToken(18), value: 'is null', label: 'is empty' },
          { id: generateToken(18), value: 'is not null', label: 'is not empty' },
        ]
      default:
        return [
          { id: generateToken(18), value: '=', label: 'is' },
          { id: generateToken(18), value: '!=', label: 'is not' },
          { id: generateToken(18), value: 'is null', label: 'is empty' },
          { id: generateToken(18), value: 'is not null', label: 'is not empty' },
          { id: generateToken(18), value: 'like', label: 'contains' },
          { id: generateToken(18), value: 'not like', label: 'does not contains' },
          { id: generateToken(18), value: 'like plain', label: 'start with' },
        ]
    }
  }

  const handleRemoveFilter = (id) => {
    setFilters(oldFilters => {
      const index = oldFilters.findIndex(filter => filter.id === id);

      if (index === -1) return oldFilters

      const newFilters = [...oldFilters];

      if (newFilters[index]?.search) newFilters[index].search = ''
      if (newFilters[index]?.startDate) newFilters[index].startDate = ''
      if (newFilters[index]?.endDate) newFilters[index].endDate = ''

      return newFilters;
    });
  }

  return (
    <Container className="w-full pt-10 bg-white border-t-2 md:w-full border-grayLighter">
      <div className="w-full px-4 md:px-0 md:mx-auto md:w-11/12">
        <Helmet>
          <title>{title} – {config.title}</title>
        </Helmet>
        <div className="flex items-center justify-between mb-8">
          < h2 className="text-xl wght-semibold" > {title}</h2 >
          <Link to={`/admin/edit/${modelName}`} className="cursor-pointer hover:wght-semibold">Add</Link>
        </div >

        {modelName === 'Post' &&
          <div className="flex gap-1 mb-1">
            <Button theme="grayLead" onClick={() => {
              setshowFilters(state => !state)
              if (!showFilters) {
                setFilters([defaultFilter])
              } else {
                setFilters([])
              }
            }}>
              <span className="flex gap-1">
                <span>
                  <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path
                      fill="#fff"
                      d="M21 4v2h-1l-6 9v7h-4v-7L4 6H3V4z"
                      className="fill-000000 fill-ffffff"
                      style={{ DarkreaderInlineFill: "#181a1b" }}
                    ></path>
                  </svg>
                </span>

                <span>
                  Filter
                </span>
              </span>
            </Button>
          </div>
        }

        {modelName === 'Post' && showFilters && Object.entries(data?.config?.list || {}).length
          && < table className="text-xs bg-white">
            <thead>
              <tr className="border-2 border-gray ">
                <th
                  className="py-1 pl-3 text-sm text-left wght-normal"
                  colSpan={3}
                >
                  In this view show records
                </th>
              </tr>
            </thead>
            <tbody>
              {filters.map((filter, index) => {
                return <tr key={filter.id} className="border-2 border-gray">
                  <td className="py-2 text-center" colSpan={1}>
                    {index !== 0
                      ? <select
                        className="p-1 border-2 rounded-sm border-gray"
                        name="logicalOperator"
                        id="logical-operator-select"
                        value={filter.logicalOperator}
                        onChange={(event) => handleFilters(filter.id, event)}
                      >
                        <option value="and">and</option>
                        <option value="or">or</option>
                      </select>
                      : <span>where</span>
                    }
                  </td>
                  <td className="py-2 pr-24" colSpan={2}>
                    <div className="flex items-center gap-1">
                      <div className="relative flex p-1 border-2 rounded-sm border-gray">
                        <select
                          className="appearance-none focus:outline-none"
                          name="column"
                          id="column-select"
                          value={filter.column}
                          onChange={(event) => handleFilters(filter.id, event)}
                        >
                          {
                            Object.entries(data.config.list).filter(([key]) => !['status', 'includedIn'].includes(key)).map(([key, value]) =>
                              <option key={`column-option-${key}`} value={key}>{modelName} {value.label?.toLowerCase() || value?.toLowerCase()}</option>
                            )
                          }
                        </select>

                        <img
                          width={18}
                          height={18}
                          src={`${process.env.PUBLIC_URL}/assets/icons/623096_down_up_arrow_arrows_direction_icon.png`}
                        />
                      </div>

                      <div className="relative flex p-1 border-2 rounded-sm border-gray">
                        <select
                          className="appearance-none focus:outline-none"
                          name="operator"
                          id="comparision-operator-select"
                          value={filter.operator}
                          onChange={(event) => handleFilters(filter.id, event)}
                        >
                          {filterOptions(filter?.column).map(({ id, value, label }) =>
                            <option key={id} value={value}>{modelName} {label?.toLowerCase()}</option>
                          )}
                        </select>

                        <img
                          width={18}
                          height={18}
                          src={`${process.env.PUBLIC_URL}/assets/icons/623096_down_up_arrow_arrows_direction_icon.png`}
                        />
                      </div>

                      <div className="flex">
                        {
                          filter.column === 'updateDate' ?
                            <div className="flex gap-1">
                              <input
                                className="p-1 pl-2 border-2 rounded-sm border-gray"
                                type="date"
                                id="start-date"
                                name="startDate"
                                value={filter.startDate}
                                onChange={(event) => handleFilters(filter.id, event)}
                              />

                              <input
                                className="p-1 border-2 rounded-sm border-gray"
                                type="date"
                                id="end-date"
                                name="endDate"
                                value={filter.endDate}
                                onChange={(event) => handleFilters(filter.id, event)}
                              />
                            </div> :
                            <input
                              className="p-1 border-2 rounded-sm border-gray"
                              type="text"
                              id="search"
                              name="search"
                              size={24}
                              placeholder="Enter value"
                              value={filter.search}
                              onChange={(event) => handleFilters(filter.id, event)}
                            />
                        }

                        <button className="flex items-center p-1 px-2 border-2 rounded-sm border-gray focus:outline-none"
                          onClick={() => handleRemoveFilter(filter.id)}
                        >
                          <span>
                            <svg width={18} height={18} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                              <path
                                fill="#788796"
                                d="M27 6h-6V5c0-1.654-1.346-3-3-3h-4c-1.654 0-3 1.346-3 3v1H5c-1.103 0-2 .897-2 2v1a1 1 0 001 1h24a1 1 0 001-1V8c0-1.103-.897-2-2-2zM13 5c0-.551.449-1 1-1h4c.551 0 1 .449 1 1v1h-6V5zm-7 7v15c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3V12H6zm13.707 10.293a.999.999 0 11-1.414 1.414L16 21.414l-2.293 2.293a.999.999 0 11-1.414-1.414L14.586 20l-2.293-2.293a.999.999 0 111.414-1.414L16 18.586l2.293-2.293a.999.999 0 111.414 1.414L17.414 20l2.293 2.293z"
                                className="fill-000000"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              })}

              <tr className="border-2 border-gray">
                <td className="py-1" colSpan={1}>
                  <button
                    className="px-3 py-1 focus:outline-none"
                    onClick={handleAddFilter}
                  >
                    + Add filter
                  </button>
                </td>
                <td className="py-1 text-right" colSpan={1}
                  onClick={() => setFilters([defaultFilter])}
                >
                  <button className="px-3 py-1 focus:outline-none ">Clear all  filters</button>
                </td>
              </tr>
            </tbody>
          </table>
        }

        {data && <Exports />}
        {!data && <div>Loading...</div>}
        {data && data.items.length === 0 && <div>Nothing to list</div>}
        {
          data && data.items.length > 0 &&
          <div className='flex mb-8'>
            <div className="flex-auto overflow-x-auto whitespace-no-wrap">
              <table className="w-full whitespace-no-wrap">
                <thead className="text-xs text-left whitespace-no-wrap bg-grayLighter wght-semibold">
                  <tr className="h-12 border-grayLight">
                    {Object.entries(data.config.list).map(([key, value]) => {
                      const icon = (sort[key] || data?.config?.sort[key]) ? (
                        <img
                          width={28}
                          height={28}
                          src={(sort[key] === 'asc' || data?.config?.sort[key] === 'asc')
                            ? `${process.env.PUBLIC_URL}/assets/icons/sort_asc_icon.png`
                            : `${process.env.PUBLIC_URL}/assets/icons/sort_desc_icon.png`
                          } />
                      ) : (
                        <img
                          width={28}
                          height={28}
                          src={`${process.env.PUBLIC_URL}/assets/icons/unsorted_icon.png`} />
                      )

                      if (typeof value === 'object') {
                        return <th
                          key={value?.label}
                          className="px-3 py-2 cursor-pointer select-none"
                          onClick={() => handleSort(key)}
                        >
                          <span className="flex items-center justify-between">
                            <span>
                              {value?.label}
                            </span>
                            {icon}
                          </span>
                        </th>
                      }

                      return <th
                        key={value}
                        className="px-3 py-2 cursor-pointer select-none"
                        onClick={() => handleSort(key)}
                      >
                        <span className="flex items-center justify-between" >
                          <span>
                            {value}
                          </span>
                          {icon}
                        </span>
                      </th>
                    }
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data.items.map((item, index) => (
                    <tr
                      key={item.id}
                      className={`relative h-12 text-xs ${index % 2 ? 'bg-grayLighter' : ''}`}>
                      {
                        Object.keys(data.config.list).filter(item => item !== 'excludedFrom').map(key => {
                          if (key === 'includedIn' && Array.isArray(item[key])) {
                            const includedIn = (item[key] || [])
                              .map(({ country, locale }) => {
                                if (country === 'all') return `${country?.toUpperCase()}`
                                const [localeLanguage, localeRegion] = locale?.split('-') || []
                                return `${localeLanguage?.toLowerCase()}-${localeRegion?.toUpperCase() || country?.toUpperCase()}`
                              })


                            return (
                              <td key={key + index} className="px-3 align-middle">
                                <div className="flex">
                                  {includedIn.map((text, i) => (
                                    <span key={text + i} className="p-1 px-2 m-1 rounded-full wght-semibold text-xxs bg-gray">{text}</span>
                                  ))}
                                </div>
                              </td>
                            );
                          }

                          if (key === 'status') {
                            return <td key={key} className="px-3 py-2 align-middle min-w-24">
                              {
                                item[key] ?
                                  <span className="px-2 py-1 text-white rounded-full bg-green wght-semibold text-xxs">Published</span>
                                  : <span className="px-2 py-1 rounded-full bg-yellow wght-semibold text-xxs">Draft</span>
                              }
                            </td>
                          }

                          if (key.includes('date') && item[key]) {
                            const completeDate = new Date(item[key])
                            const date = completeDate.toLocaleDateString('en-GB', {
                              day: '2-digit',
                              month: 'short',
                              year: 'numeric',
                              timeZone: 'Europe/Madrid'
                            });
                            const hour = completeDate.toLocaleTimeString('en-GB', {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                              timeZone: 'Europe/Madrid'
                            });

                            return <td key={key} className="px-3 align-middle min-w-34">
                              <span className="flex flex-wrap">
                                <span className="w-full text-xs whitespace-no-wrap wght-semibold">{date}</span>
                                <span className="opacity-75 text-xxs wght-semibold ">{hour.toUpperCase()}</span>
                              </span>
                            </td>
                          }

                          if (key === 'ref') {
                            return <td key={key} className="max-w-xs px-3 overflow-hidden min-w-xs">
                              <span style={{
                                whiteSpace: 'normal',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}>
                                {typeof item[key] !== 'undefined' && item[key]}
                              </span>
                            </td>
                          }

                          return <td key={key} className="px-3 py-2 min-w-16">
                            {typeof item[key] !== 'undefined' && item[key]?.replace(/,/g, ', ')}
                          </td>
                        })
                      }
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <table className="whitespace-no-wrap">
                <thead className="text-xs text-left whitespace-no-wrap bg-grayLighter wght-semibold">
                  <tr className="h-12 border-l-2 hover:bg-primaryLight border-grayLight">
                    <th className="sticky right-0 px-3 py-2 md:px-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.items.map((item, index) => (
                    <tr key={item.id} className={`h-12 text-xs relative border-grayLight border-l-2 ${index % 2 ? 'bg-grayLighter' : ''}`}>
                      <td className="px-3 py-2 text-xs">
                        <Link className="mr-4 cursor-pointer hover:wght-semibold" to={`/admin/edit/${modelName}/${item.id}`}>Edit</Link>
                        {['Category', 'Printer', 'Webinar', 'Post', 'Story'].includes(modelName) &&
                          <a target="_blank" className="mr-4" href={`${config.apiURL}api/redirect/${modelName}/${item.id}`}>Preview</a>
                        }
                        {['CustomFile'].includes(modelName) &&
                          <a target="_blank" className="mr-4" href={`${config.apiURL}api/download/${item.slug}`}>Download</a>
                        }
                        <span className="mr-4 cursor-pointer hover:wght-semibold" onClick={() => duplicate(item.id)}>Duplicate</span>
                        <span className="cursor-pointer hover:wght-semibold" onClick={() => remove(item.id)}>Delete</span>
                      </td>
                    </tr >
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        }
        {
          hasMorePages &&
          <div className="flex mt-8">
            {page > 0 && <span onClick={() => setPage(page - 1)} className="mr-4 cursor-pointer hover:wght-semibold">Previous page</span>}
            <span onClick={() => setPage(page + 1)} className="mr-4 cursor-pointer hover:wght-semibold">Next page</span>
          </div>
        }
      </div >
    </Container >
  )
}

export default List