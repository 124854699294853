const config = {
  environment: process.env.REACT_APP_ENV === 'production' ? 'production' : 'staging',
  title: process.env.REACT_APP_ENV === 'production' ? 'HP Virtual' : '[Staging] HP Virtual',
  apiURL: process.env.REACT_APP_ENV === 'production' ? 'https://c.hpvirtualbooth.com/api/public/' : 'https://c.hpvirtualbooth.com/api-staging/public/',
  mediaURL: 'https://4n7dissrid.execute-api.eu-west-3.amazonaws.com/',
  mediaName: 'hpvirtualbooth',
  menuStories: [
    {
      type: 'Story',
      title: 'Stories',
    },
    {
      type: 'StoryCategory',
      title: 'Categories',
    },
    {
      type: 'StoryIndustry',
      title: 'Industries',
    },
  ],
  menuBlog: [
    {
      type: 'Post',
      title: 'Posts',
    },
    {
      type: 'PostCategory',
      title: 'Categories',
    },
  ],
  menuWebinar: [
    {
      type: 'Webinar',
      title: 'Webinars',
    },
    {
      type: 'WebinarAlert',
      title: 'Webinar alerts',
    },
  ],
  menu: [
    {
      type: 'Home',
      title: 'Home',
      path: '/admin/edit/Home/1',
      items: [
        {
          type: 'Home',
          title: 'Home',
          path: '/admin/edit/Home/1',
        },
        {
          type: 'Webinar',
          title: 'Webinars',
        },
        {
          type: 'WebinarAlert',
          title: 'Webinar alerts',
        },
        {
          type: 'Podcast',
          title: 'Podcasts',
        },
      ]
    },
    {
      type: 'Category',
      title: 'Verticals',
    },
    {
      type: 'Printer',
      title: 'Printers',
      items: [
        {
          type: 'Printer',
          title: 'Printers',
        },
        {
          type: 'ShoppablePrinter',
          title: 'Shoppable Printers',
        },
        {
          type: 'PrinterCategory',
          title: 'Category',
        },
        {
          type: 'PrinterOptionInk',
          title: 'Ink options',
        },
        {
          type: 'PrinterOptionMaterial',
          title: 'Material options',
        },
        {
          type: 'PrinterOptionProductivity',
          title: 'Productivity options',
        },
        {
          type: 'PrinterOptionRollWidth',
          title: 'Roll Width options',
        },
      ]
    },
    {
      type: 'Story',
      title: 'Stories',
      items: [
        {
          type: 'Story',
          title: 'Stories',
        },
        {
          type: 'StoryCategory',
          title: 'Categories',
        },
        {
          type: 'StoryIndustry',
          title: 'Industries',
        },
      ]
    },
    {
      type: 'Solution',
      title: 'Solutions',
      items: [
        {
          type: 'Solution',
          title: 'Solutions',
        },
        {
          type: 'SolutionCategory',
          title: 'Categories',
        },
      ]
    },
    {
      type: 'Post',
      title: 'Posts',
      items: [
        {
          type: 'Post',
          title: 'Posts',
        },
        {
          type: 'PostTag',
          title: 'Tags',
        },
        {
          type: 'PostCategory',
          title: 'Categories',
        },
        {
          type: 'PageBlog',
          title: 'Landing',
          path: '/admin/edit/PageBlog/1',
        },
      ]
    },
    {
      type: 'Common',
      title: 'Common',
      path: '/admin/edit/Common/1',
      items: [
        {
          type: 'Common',
          title: 'Common',
          path: '/admin/edit/Common/1',
        },
        {
          type: 'Header',
          title: 'Headers',
        },
        {
          type: 'MenuLink',
          title: 'Menu Links',
        },
      ]
    },
    {
      type: 'Video',
      title: 'Videos',
      items: [
        {
          type: 'Video',
          title: 'Videos',
        },
        {
          type: 'VideoCategory',
          title: 'Video categories',
        },
      ]
    },
    {
      type: 'HowTo',
      title: 'How To',
      items: [
        {
          type: 'HowTo',
          title: 'How To',
        },
        {
          type: 'HowToCategory',
          title: 'How To categories',
        },
      ]
    },
    {
      type: 'CustomFile',
      title: 'Files',
      items: [
        {
          type: 'CustomFile',
          title: 'Files',
        },
      ]
    },
    {
      type: 'Printer3dPoint',
      title: '3d points',
      items: [
        {
          type: 'Printer3dPoint',
          title: '3d points',
        },
        {
          type: 'Printer3d',
          title: '3d printer',
        },
      ]
    },
    {
      type: 'Literal',
      title: 'Config',
      items: [
        {
          type: 'Literal',
          title: 'Texts',
        },
        {
          type: 'ContactPrinter',
          title: 'Contact Page Printers',
        },
        {
          type: 'ContactIndustry',
          title: 'Contact Industries',
        },
        {
          type: 'Country',
          title: 'Countries',
        },
        {
          type: 'Language',
          title: 'Languages',
        },
        {
          type: 'User',
          title: 'Users',
        },
      ]
    },
  ],
}

export default config
